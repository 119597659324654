import Cookies from 'js-cookie'
const type="kehu"
const TokenKey = 'Admin-Token'
const typeKey = 'typeKey'
const userKey = 'userid'
const comID = 'comId'
const admModule = 'admModule'

export function setModule(module) {
  return Cookies.set(admModule, module)
}

export function getAdmModule() {
  return Cookies.get(admModule)
}


export function getToken() {
  let path=window.location.host.split(":")[1];
  return Cookies.get(TokenKey+path+type)
}

export function setToken(name,token) {
  var expirationDate = new Date();
  expirationDate.setHours(24); // 将小时数设置为24（表示第二天）
  expirationDate.setMinutes(0); // 将分钟数设置为0（表示第二天）
  expirationDate.setSeconds(0); // 将分钟数设置为0（表示第二天）
  let options = {};
  // if (time !== null && time !== "" && time !== undefined) {
    options.expires = expirationDate;
  // }
  let path=window.location.host.split(":")[1];
  Cookies.set(TokenKey+path+type, token,options)
}

export function setcomId(comId) {
  return Cookies.set(comID, comId)
}

export function getComID() {
  return Cookies.get(comID)
}

export function removeToken() {
  let path=window.location.host.split(":")[1];
  return Cookies.remove(TokenKey+path+type)
}

export function getUserid() {
  return Cookies.get(userKey)
}

export function setUserid(userid) {
  return Cookies.set(userKey, userid)
}

export function removeUserid() {
  return Cookies.remove(userKey)
}

export function setAccType(type) {
  return Cookies.set(typeKey, type)
}

export function getAccType() {
  return Cookies.get(typeKey)
}

export  function getUrlKey(name){
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

